var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{ref:"cardTop",staticClass:"d-flex align-items-center mb-3 bg-body"},[_c('h3',{staticClass:"me-auto"},[_vm._v("Adjust shown "+_vm._s(_vm.sectionName))]),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){_vm.finalized = !_vm.finalized}}},[(!_vm.finalized)?_c('span',[_vm._v("Finalize")]):_c('span',[_vm._v("Unfinalize")])])]),(!_vm.finalized)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-lg-6 border-end h-100"},[_c('h4',{staticClass:"bg-white"},[_vm._v("Base Report")]),_c('ul',{staticClass:"list-group"},_vm._l((Object.keys(_vm.baseSection)),function(item){return _c('li',{key:(_vm.sectionName + "-" + item),class:[
                  'd-flex',
                  'align-items-center',
                  'list-group-item',
                  Object.keys(_vm.value).includes(item)
                    ? 'bg-success-lt'
                    : 'bg-white' ]},[_vm._t("baseItem",function(){return [_vm._v(" "+_vm._s(item)+" ("+_vm._s(_vm.baseSection[item])+") ")]},{"item":{ key: item, value: _vm.baseSection[item] }}),_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":function($event){return _vm.toggleFinal(item)}}},[(!Object.keys(_vm.value).includes(item))?_c('span',[_vm._v("Use")]):_c('span',[_vm._v("Remove")])])],2)}),0)]),_c('div',{staticClass:"col-lg-6 mh-100"},[_c('div',{staticClass:"d-flex position-sticky top-0 bg-white align-items-center",staticStyle:{"z-index":"100000"}},[_c('h4',{},[_vm._v(" Final Report (Expects "+_vm._s(_vm.expects - Object.keys(_vm.value).length)+" more) ")]),_c('button',{staticClass:"btn btn-primary ms-auto",on:{"click":function($event){_vm.finalized = !_vm.finalized}}},[(!_vm.finalized)?_c('span',[_vm._v("Finalize")]):_c('span',[_vm._v("Unfinalize")])])]),_c('ul',{staticClass:"list-group"},_vm._l((Object.keys(_vm.value)),function(item){return _c('li',{key:("final-" + _vm.sectionName + "-" + item),class:[
                  'd-flex',
                  'align-items-center',
                  'list-group-item',
                  'bg-white' ]},[_vm._t("finalItem",function(){return [_vm._v(" "+_vm._s(item)+" ("+_vm._s(_vm.value[item])+") ")]},{"item":{ key: item, value: _vm.value[item] }}),_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":function($event){return _vm.toggleFinal(item)}}},[(!Object.keys(_vm.value).includes(item))?_c('span',[_vm._v("Use")]):_c('span',[_vm._v("Remove")])])],2)}),0)])])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="row mb-3">
    <div class="col-lg-12">
      <div class="d-flex align-items-center mb-3 bg-body" ref="cardTop">
        <h3 class="me-auto">Adjust shown {{ sectionName }}</h3>
        <button class="btn btn-primary" @click="finalized = !finalized">
          <span v-if="!finalized">Finalize</span>
          <span v-else>Unfinalize</span>
        </button>
      </div>
      <div class="card" v-if="!finalized">
        <div class="card-body">
          <div class="row h-100">
            <div class="col-lg-6 border-end h-100">
              <h4 class="bg-white">Base Report</h4>
              <ul class="list-group">
                <li
                  v-for="item in Object.keys(baseSection)"
                  :key="`${sectionName}-${item}`"
                  :class="[
                    'd-flex',
                    'align-items-center',
                    'list-group-item',
                    Object.keys(value).includes(item)
                      ? 'bg-success-lt'
                      : 'bg-white',
                  ]"
                >
                  <slot
                    name="baseItem"
                    v-bind:item="{ key: item, value: baseSection[item] }"
                  >
                    {{ item }} ({{ baseSection[item] }})
                  </slot>

                  <button
                    class="btn btn-primary ms-3"
                    @click="toggleFinal(item)"
                  >
                    <span v-if="!Object.keys(value).includes(item)">Use</span>
                    <span v-else>Remove</span>
                  </button>
                </li>
              </ul>
            </div>

            <div class="col-lg-6 mh-100">
              <div
                class="d-flex position-sticky top-0 bg-white align-items-center"
                style="z-index: 100000"
              >
                <h4 class="">
                  Final Report (Expects
                  {{ expects - Object.keys(value).length }} more)
                </h4>

                <button
                  class="btn btn-primary ms-auto"
                  @click="finalized = !finalized"
                >
                  <span v-if="!finalized">Finalize</span>
                  <span v-else>Unfinalize</span>
                </button>
              </div>
              <ul class="list-group">
                <li
                  v-for="item in Object.keys(value)"
                  :key="`final-${sectionName}-${item}`"
                  :class="[
                    'd-flex',
                    'align-items-center',
                    'list-group-item',
                    'bg-white',
                  ]"
                >
                  <slot
                    name="finalItem"
                    v-bind:item="{ key: item, value: value[item] }"
                  >
                    {{ item }} ({{ value[item] }})
                  </slot>

                  <button
                    class="btn btn-primary ms-3"
                    @click="toggleFinal(item)"
                  >
                    <span v-if="!Object.keys(value).includes(item)">Use</span>
                    <span v-else>Remove</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "admin.reports.audience.section-editor",
  props: {
    sectionName: String,
    expects: Number,
    baseSection: Object,
    value: Object,
  },
  data() {
    return {
      finalized: false,
    };
  },
  watch: {
    finalized() {
      if (this.finalized) {
        this.$nextTick(() => {
          this.$refs["cardTop"].scrollIntoView(true);
        });
      }
    },
  },
  methods: {
    toggleFinal(key) {
      if (this.value[key]) {
        this.$delete(this.value, key);
      } else {
        this.$set(this.value, key, this.baseSection[key]);
      }
    },
  },
};
</script>

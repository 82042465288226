<template>
  <div v-if="site">
    <div class="container mt-3 border-bottom">
      <div class="row mb-3">
        <div class="col-lg-12 d-flex">
          <h3 class="me-auto">Audience Report Builder</h3>
          <h3>{{ site.sitename }}</h3>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <h5>Base Report Version</h5>
          <select class="form-select" v-model="selectedBaseReport">
            <option
              v-for="report in allReports"
              :value="report"
              :key="`report-${report}`"
            >
              {{ report }}
            </option>
          </select>
        </div>

        <div class="col-lg-6">
          <h5>
            Final Report Version
            <span v-if="canCreateNewFinal"
              >(
              <a class="text-primary" href="#" @click.prevent="newFinal">
                Create New
              </a>
              )</span
            >
          </h5>
          <select class="form-select" v-model="selectedFinalReport">
            <option
              v-for="report in finalizedReports"
              :key="`final-report-${report}`"
              :value="report"
            >
              {{ report }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="container mt-3" v-if="loaded">
      <section-editor
        :expects="30"
        :sectionName="'search_terms'"
        :baseSection="report ? report.site_search_console : {}"
        v-model="builtReport.site_search_console"
      >
      </section-editor>

      <section-editor
        :expects="9"
        :sectionName="'domains'"
        :baseSection="report ? report.domain : {}"
        v-model="builtReport.domain"
      >
        <template v-slot:baseItem="props">
          <img
            :src="`https://logo.clearbit.com/${props.item.key}`"
            style="width: 36px; height: 36px"
            class="me-2"
          />
          {{ props.item.key }} ({{ props.item.value }})

          <a :href="`https://${props.item.key}`" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-external-link"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
              ></path>
              <path d="M10 14l10 -10"></path>
              <path d="M15 4l5 0l0 5"></path>
            </svg>
          </a>
        </template>

        <template v-slot:finalItem="props">
          <img
            :src="`https://logo.clearbit.com/${props.item.key}`"
            style="width: 36px; height: 36px"
            class="me-2"
          />
          {{ props.item.key }} ({{ props.item.value }})

          <a :href="`https://${props.item.key}`" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-external-link"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
              ></path>
              <path d="M10 14l10 -10"></path>
              <path d="M15 4l5 0l0 5"></path>
            </svg>
          </a>
        </template>
      </section-editor>

      <section-editor
        :expects="30"
        :sectionName="'intents'"
        :baseSection="report ? report.visitor_intents : {}"
        v-model="builtReport.visitor_intents"
      >
      </section-editor>

      <section-editor
        :expects="4"
        :sectionName="'industry'"
        :baseSection="report ? report.industry : {}"
        v-model="builtReport.industry"
      >
      </section-editor>

      <div class="row mb-3">
        <button class="btn btn-primary" @click="finalize">
          Finalize Report
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SectionEditor from "../../components/Admin/Reports/Audience/SectionEditor.vue";
import NotificationsService from "../../services/NotificationsService";
import SiteService from "../../services/SiteService";

export default {
  name: "admin.audience-report",
  components: {
    SectionEditor,
  },
  data() {
    return {
      siteId: 0,
      site: null,

      report: null,
      currentFile: null,
      allReports: [],
      finalizedReports: [],

      selectedBaseReport: null,
      selectedFinalReport: null,

      builtReport: {
        visitor_intents: {},
        industry: {},
        domain: {},
        seniority: {},
        company_size: {},
        company_revenue: {},
        functional_area: {},
        professional_groups: {},
        predictive_signals: {},
        site_search_console: {},
        visits: 0,
        impressions: 0,
        search_impressions: 0,
        actions: 0,
      },
      loaded: false,
      dirty: false,
    };
  },
  computed: {
    canCreateNewFinal() {
      if (this.finalizedReports.length === 0) {
        return true;
      }

      const zeroPad = (num, places) => String(num).padStart(places, "0");
      let d = new Date();
      let month = d.getUTCMonth() + 1;
      let year = d.getUTCFullYear();
      let thisMonthFinal = `_${zeroPad(month, 2)}_${year}.json`;
      return (
        this.finalizedReports.filter((r) => r.includes(thisMonthFinal))
          .length === 0
      );
    },
  },
  methods: {
    blankReport() {
      this.$set(this.builtReport, "visitor_intents", {});
      this.$set(this.builtReport, "industry", {});
      this.$set(this.builtReport, "domain", {});
      this.$set(this.builtReport, "seniority", {});
      this.$set(this.builtReport, "company_size", {});
      this.$set(this.builtReport, "company_revenue", {});
      this.$set(this.builtReport, "functional_area", {});
      this.$set(this.builtReport, "professional_groups", {});
      this.$set(this.builtReport, "predictive_signals", {});
      this.$set(this.builtReport, "site_search_console", {});

      this.$set(this.builtReport, "visits", 0);
      this.$set(this.builtReport, "impressions", 0);
      this.$set(this.builtReport, "search_impressions", 0);
      this.$set(this.builtReport, "actions", 0);
    },
    newFinal() {
      const zeroPad = (num, places) => String(num).padStart(places, "0");

      let d = new Date();
      let month = d.getUTCMonth() + 1;
      let year = d.getUTCFullYear();
      this.blankReport();
      let newFinalName = `audience_insights/finalized_visitor_insights_${
        this.siteId
      }_${zeroPad(month, 2)}_${year}.json`;

      this.finalizedReports.push(newFinalName);
      this.selectedFinalReport = newFinalName;
    },
    async finalize() {
      if (this.selectedFinalReport === null) {
        return;
      }

      this.builtReport.seniority = this.report.seniority;
      this.builtReport.company_size = this.report.company_size;

      this.builtReport.visits = this.report.visits;
      this.builtReport.impressions = this.report.impressions;
      this.builtReport.search_impressions = this.report.search_impressions;
      this.builtReport.actions = this.report.actions;

      await NotificationsService.finalizeAudienceReport(
        this.siteId,
        this.selectedFinalReport,
        this.builtReport
      );
      this.$router.push({
        path: "/reports/audience-report",
        query: { site: this.siteId },
      });
    },
    async loadReport(reportName) {
      return await NotificationsService.getSpecificAudienceReport(reportName);
    },
    async loadReports(siteId) {
      return await NotificationsService.getAllAudienceReports(siteId);
    },

    async reloadBaseReport() {
      let report = await this.loadReport(this.selectedBaseReport);
      this.$set(this, "report", report);
    },

    async reloadFinalReport() {
      let report = await this.loadReport(this.selectedFinalReport);
      if (!report) {
        this.blankReport();
      } else {
        for (const [key, value] of Object.entries(report)) {
          if (value == null) {
            report[key] = {};
          }
        }
        this.$set(this, "builtReport", report);
      }
    },
  },
  watch: {
    selectedBaseReport: async function (val) {
      if (!val) return;

      await this.reloadBaseReport();
    },
    selectedFinalReport: async function (val) {
      if (!val) return;

      await this.reloadFinalReport();
    },
  },
  async mounted() {
    this.siteId = this.$route.query.site;
    this.site = await SiteService.getSite(this.siteId);

    let reportInfo = await this.loadReports(this.$route.query.site);

    this.allReports = reportInfo.allReports;
    this.finalizedReports = reportInfo.finalReports;

    if (this.allReports.length > 0) {
      this.selectedBaseReport = this.allReports[0];
      await this.reloadBaseReport();
    }

    if (this.finalizedReports.length > 0) {
      this.selectedFinalReport = this.finalizedReports[0];
      await this.reloadFinalReport();
    }

    this.loaded = true;
  },
};
</script>
